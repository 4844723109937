import { Feedback } from '@/features/user/chat/types'

export const apiVersion = '/v1'

export const API = {
  login: `${apiVersion}/login`,
  me: `${apiVersion}/me`,
  chatGpt: `${apiVersion}/chat-gpt`,
  chatClaude: `${apiVersion}/chat-claude`,
  chatGemini: `${apiVersion}/chat-gemini`,
  chatImage: `${apiVersion}/chat/images`,
  chatModels: `${apiVersion}/chat/models`,
  history: `${apiVersion}/chat-rooms`,
  information: (query?: string) =>
    query ? `${apiVersion}/information?${query}` : `${apiVersion}/information`,
  informationDetail: (id: string) => `${apiVersion}/information/${id}`,
  feedback: (chatLogId: string, data: Feedback) =>
    `${apiVersion}/feedback/${chatLogId}?evaluation=${data.evaluation}${data.comment !== '' ? '&comment=' + data.comment : ''}`,
  group: `${apiVersion}/chat/original-prompts/groups`,
  genre: (id: string) => `${apiVersion}/chat/original-prompts/groups/${id}`,
  // prompt
  prompts: (query: string) => `${apiVersion}/original-prompts?${query}`,
  myPrompts: (query?: string) => `${apiVersion}/my-prompts?${query}`,
  favoritePrompts: (query: string) => `${apiVersion}/original-prompts/favorites?${query}`,

  incrementPromptUsed: (promptId: string) =>
    `${apiVersion}/original-prompts/usage_count/${promptId}`,
  newPrompt: `${apiVersion}/my-prompts`,
  updatePrompt: `${apiVersion}/my-prompts`,
  deletePrompt: (id: string) => `${apiVersion}/my-prompts/${id}`,
  toggleFavorite: (id: string) => `${apiVersion}/original-prompts/${id}/favorites`,
  // box
  box: (search_word?: string) =>
    `${apiVersion}/boxes${search_word ? `?search_word=${search_word}` : ''}`,
  containingBox: (id: string) => `${apiVersion}/boxes/${id}`,
  promptsInBox: (id: string, query: string) =>
    `${apiVersion}/prompt_boxes/${id}${query ? `?${query}` : ''}`,
  newBox: (value: string) => `${apiVersion}/boxes?box_name=${value}`,
  deleteBox: (id: string) => `${apiVersion}/boxes?box_id=${id}`,
  changeBox: (id: string) => `${apiVersion}/prompt_boxes/create_delete?prompt_id=${id}`,

  prompt: (id: string) => `${apiVersion}/original-prompts/${id}`,
  //admin
  students: (query?: string) =>
    query ? `${apiVersion}/admin/student?${query}` : `${apiVersion}/admin/student`,
  teachers: (query?: string) =>
    query ? `${apiVersion}/admin/teacher?${query}` : `${apiVersion}/admin/teacher`,
  authority: (query: string) => `${apiVersion}/admin/users/authority?${query}`,
  chatRooms: (query?: string) =>
    query ? `${apiVersion}/admin/chat-rooms?${query}` : `${apiVersion}/admin/chat-rooms`,
  csvDownload: (query?: string) =>
    query ? `${apiVersion}/admin/logs/csv?${query}` : `${apiVersion}/admin/logs/csv`,
  chatRoom: (id: string) => `${apiVersion}/admin/chat-rooms/${id}`,
  policy: (query?: string) =>
    query ? `${apiVersion}/admin/policy?${query}` : `${apiVersion}/admin/policy`,
  policyDetail: (id: string) => `${apiVersion}/admin/policy/${id}`,
  adminGroupDetail: (id: string) => `${apiVersion}/admin/original-prompts/groups/${id}`,
  adminGenreDetail: (id: string) => `${apiVersion}/admin/original-prompts/genres/${id}`,
  adminGroup: (query?: string) =>
    query
      ? `${apiVersion}/admin/original-prompts/groups?${query}`
      : `${apiVersion}/admin/original-prompts/groups`,
  adminGroupDelete: (id: string) => `${apiVersion}/admin/original-prompts/groups/${id}`,
  adminGenreDelete: (id: string) => `${apiVersion}/admin/original-prompts/genres/${id}`,
  adminFeedback: (query?: string) =>
    query ? `${apiVersion}/admin/feedback?${query}` : `${apiVersion}/admin/feedback`,
  adminFeedbackDetail: (id: string) => `${apiVersion}/admin/feedback/${id}`,
  AdminPrompts: (query?: string) =>
    query
      ? `${apiVersion}/admin/original-prompts?${query}`
      : `${apiVersion}/admin/original-prompts`,
  AdminPromptDelete: (id: string) => `${apiVersion}/admin/original-prompts/${id}`,
  AdminUpdatePrompt: (id: string, query: string) =>
    `${apiVersion}/admin/original-prompts/${id}${query ? `?${query}` : ''}`,
  AdminPrompt: (id: string) => `${apiVersion}/admin/original-prompts/${id}`,
  adminGroupCreate: (query: string) =>
    `${apiVersion}/admin/original-prompts/groups/registration?${query}`,
  adminGenreCreate: (query: string) =>
    `${apiVersion}/admin/original-prompts/genres/registration?${query}`,
  adminGroupUpdate: (query: string) =>
    `${apiVersion}/admin/original-prompts/groups?${query}`,
  adminGenreUpdate: (query: string) =>
    `${apiVersion}/admin/original-prompts/genres?${query}`,
}
