import { useState } from 'react'
import { SnackbarCloseReason } from '@mui/material'
import { Flash } from '@/types/common'

export type SnackbarOptions = {
  text: string
  severity: Flash['level']
}

export const useSnackbarState = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState<Flash['level']>('error')

  const openSnackbar = ({ text, severity }: SnackbarOptions) => {
    setMessage(text)
    setSeverity(severity)
    setIsOpen(true)
  }

  const closeSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setIsOpen(false)
  }

  return {
    isOpen,
    message,
    severity,
    openSnackbar,
    closeSnackbar,
  }
}
