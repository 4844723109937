import styled from '@emotion/styled'
import { Backdrop, Box, CircularProgress } from '@mui/material'

const FlexBox = styled(Box)`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: space-around;
`

type LoadingProps = {
  isOpen?: boolean
  size?: number
  overlay?: boolean
  centered?: boolean
}

export const Loading = ({
  isOpen = false,
  size = 40,
  centered = false,
  overlay = false,
}: LoadingProps) => {
  return centered ? (
    <FlexBox>
      <CircularProgress size={size} />
    </FlexBox>
  ) : overlay ? (
    <Backdrop open={isOpen} sx={{ zIndex: 10000, height: '100%' }}>
      <CircularProgress size={size} />
    </Backdrop>
  ) : (
    <CircularProgress size={size} />
  )
}
