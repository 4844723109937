import '../styles/reset.css'
import '../styles/global.css'
import type { AppProps } from 'next/app'
import { NextPage } from 'next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material'

import { theme } from '@/utils/themeConfigs'
import { SnackbarProvider } from '@/context/SnackbarContext'
import { AuthProvider } from '@/context/AuthContext'
import { Meta } from '@/components/others/Meta'
import { InfoProvider } from '@/context/InfoConrtext'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const queryClient = new QueryClient()

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <SnackbarProvider>
      <QueryClientProvider client={queryClient}>
        <InfoProvider>
          <AuthProvider>
            <MUIThemeProvider theme={theme}>
              <CssBaseline />
              {process.env.NEXT_PUBLIC_USER !== 'web' && <Meta />}
              {getLayout(<Component {...pageProps} />)}
            </MUIThemeProvider>
          </AuthProvider>
        </InfoProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  )
}

export default App
